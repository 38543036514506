<script>
  import Layout from "../../layouts/main";
  import PageHeader from "@/components/page-header";
  import test from "@/app.config.json";
  import axios from "axios";

  export default {
    page: {title: "EINSTUDIO-My-Studios",meta: [{name: "description"}]},
    components: {
      Layout,PageHeader
    },data() {
      return {
        title: "MY STUDIOS",
        items: [{text: "Home"}, {text: "My Studios",active: true}],
        baseUrl:test.baseUrl,user:[],groups:[],followers:[],selectedFollowers:[],groupId:"",name:"",
        explanation:"",groupTeacherCapacity:0,groupStudentCapacity:0,isOnlyTeacher:false,isPassword:false,password:"",id:"",filter:''
      };
    },computed:{
      filteredrows(){
        return this.followers.filter(row=>{
          const type=row.userGroup.name.toString().toLowerCase();
          const username= row.fullName.toString().toLowerCase();
          const searchTerm= this.filter.toLowerCase();
          return type.includes(searchTerm) || username.includes(searchTerm);
        })
      }
    },created:function(){
   
    },mounted:function() {
      this.user = JSON.parse(localStorage.getItem('user'));
      this.loadData();
    },methods: {
        addData(){
         let loader = this.$loading.show();

         var formToPost = new FormData(document.getElementById("add-group-form"));
         formToPost.append("ownerId",this.user.id)
         formToPost.append("isPassword",this.isPassword)
         formToPost.append("isOnlyTeacher",this.isOnlyTeacher)

         axios.post(`${this.baseUrl}/Group/Add`, formToPost, {
          headers: {
            Authorization: `Bearer ${this.user.jwt}`,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => 
        {
          loader.hide();
          this.$swal("",response.data.message, "success");
          this.loadData();
          this.hideModal('modal-lg');
        })
        .catch((error) => 
        {
          loader.hide();
          var html="";
          if(error.response.data.length>0)
          {
            for (let index = 0; index < error.response.data.length; index++) 
            {
              html +="<small>"+ error.response.data[index].message+"</small><hr>";
            }
          }
          else
          {
            html ="<p class='small'>"+ error.response.data.message+"</p>";
          }
          this.$swal("",html,"error");
        });
      },editData(){
         let loader = this.$loading.show();

         var formToPost = new FormData(document.getElementById("edit-file-form"));

         formToPost.append("ownerId",this.user.id)
         formToPost.append("isPassword",this.isPassword)
         formToPost.append("isOnlyTeacher",this.isOnlyTeacher)
         formToPost.append("id",this.id)

         axios.put(`${this.baseUrl}/Group/Set`, formToPost, {
          headers: {
            Authorization: `Bearer ${this.user.jwt}`
          },
        })
        .then((response) => 
        {
          loader.hide();
          this.$swal("",response.data.message, "success");
          this.hideModal('modal-edit');
          this.loadData(this.id);
        })
        .catch((error) => 
        {
          loader.hide();
          var html="";
          if(error.response.data.length>0)
          {
            for (let index = 0; index < error.response.data.length; index++) 
            {
              html +="<small>"+ error.response.data[index].message+"</small><hr>";
            }
          }
          else
          {
            html ="<p class='small'>"+ error.response.data.message+"</p>";
          }
          this.$swal("",html,"error");
        });
      },loadData(){
         let loader=this.$loading.show()

          axios.get(this.baseUrl+"/Group/GetAll?status=true&pagesize=0&pageindex=0&userId="+this.user.id,{
            headers:{
                Authorization: `Bearer ${this.user.jwt}`,
            }
          }).then((response)=>{
              this.groups= response.data;
              loader.hide();
          }).catch((error)=>{
           const html ="<small>"+ error.response.data.message+"</small>";
           this.$swal("",html,"error");
          })
      },initialData(id){
         // let loader= this.$loading.show();
          if(this.user.rol=='admin')
            {
              this.pass="admin";
            }
             axios.get(this.baseUrl+"/Group/GetById/"+id, { params:{pass:this.pass}, headers:{ Authorization: `Bearer ${this.user.jwt}`}
          }).then((response) => 
          {
            this.name= response.data.name;
            this.explanation=response.data.explanation;
            this.groupId=response.data.groupId;
            this.ownerId=response.data.ownerId;
            this.isOnlyTeacher=response.data.isOnlyTeacher;
            this.groupStudentCapacity=response.data.groupStudentCapacity;
            this.groupTeacherCapacity=response.data.groupTeacherCapacity;
            this.isPassword= response.data.isPassword;
            this.password=response.data.password;
            this.id=response.data.id;

            if(this.isPassword)
            {
              let item= document.getElementById("password");
              item.classList.remove("bg-danger");
              item.classList.add("bg-success");
              item.removeAttribute("disabled");
            }

           // loader.hide();
          })
          .catch((error) => 
          {
            //loader.hide();
            if(error.response.data.model.ownerId==this.user.id){
              this.pass="admin";
              console.log(error.response.data.model)
              this.initialData(error.response.data.model.id)
            }
            else{
              var html ="<p class='small'>"+ error.response.data.message+"</p>";
             this.$swal("",html,"error");
            }

          });
      },loadUsers(id){

        this.groupId=id;
        this.selectedFollowers=[]
        axios.get(this.baseUrl+"/User/GetAll?status?true",{
          headers:{
              Authorization: `Bearer ${this.user.jwt}`,
          }
        }).then((response)=>{
            this.followers= response.data.filter(x=>x.userGroup.id!=1);
            var usr=this.groups.find(x=>x.id==this.groupId).groupUsers;
            for (let index = 0; index < usr.length; index++) 
            {
              this.selectedFollowers.push(usr[index].userId) 
            }
        }).catch((error)=>{
          const html ="<small>"+ error.response.data.message+"</small>";
          this.$swal("",html,"error");
        })
      },isPasswordFunc(){
        let check=document.getElementById("isPassword");
        let item= document.getElementById("password");
        if(check.checked){
            item.removeAttribute("disabled");
            item.setAttribute("placeholder","Only 8 Characters");
            item.classList.remove("bg-danger");
            item.classList.add("bg-success");
            this.isPassword=true;
        }
        else {
            item.setAttribute("disabled","disabled");
            item.removeAttribute("placeholder");
            item.classList.remove("bg-success");
            item.classList.add("bg-danger");
            item.value="";
            this.isPassword=false;
        }
      },AllCheck:function(){
        let allcheck=document.getElementById("allcheck")
        document.getElementsByName("checkuser").forEach(x=>x.checked=allcheck.checked);
        if(allcheck.checked)
        {
          let array=document.getElementsByName("checkuser")
          this.selectedFollowers=[]
          for (let index = 0; index < array.length; index++) 
          {
            const element = array[index].value;
            this.selectedFollowers.push(element)
          }
        }
        else
        this.selectedFollowers=[]

        console.log(this.selectedFollowers)
      },selectedUsers:function(ev){
       
       if(ev.target.checked)
       {

         if(this.selectedFollowers.indexOf(ev.target.value)==-1)
          this.selectedFollowers.push(ev.target.value)
       }
       else
       {
         var index = this.selectedFollowers.indexOf(parseInt(ev.target.value));
         if(index!=-1)
         {
           this.selectedFollowers.splice(index, 1);
           this.deleteFollowers(ev.target.value);
         }
       }
      },addFollowers(){

        if(this.selectedFollowers.length==0){
        this.$swal("","select one or more users.","error");
          return;
        }

        let loader = this.$loading.show();
        axios.post(`${this.baseUrl}/Group/GroupUSers/Add`,this.selectedFollowers,   {
        params: {groupId: this.groupId},
        headers: {
          Authorization: `Bearer ${this.user.jwt}`
        },
        })
        .then((response) => 
        {
          this.loadData();
          this.loadUsers(this.groupId);
          loader.hide();
          this.$swal("",response.data.message, response.data.style);
          this.hideModal('modal-follower');
        })
        .catch((error) => 
        {
        loader.hide();
        var html="";
        if(error.response.data.length>0)
        {
          for (let index = 0; index < error.response.data.length; index++) 
          {
            html +="<p>"+ error.response.data[index].message+"</p>";
          }
        }
        else
        {
          html ="<p>"+ error.response.data.message+"</p>";
        }
        this.$swal("Warning",html,error.response.data.style);
        });
      },deleteFollowers(id){
        let loader= this.$loading.show();
        axios.delete(this.baseUrl+'/Group/GroupUsers/Delete/'+id+'/'+this.groupId, {
            headers: { Authorization: `Bearer ${this.user.jwt}`}
        }).then((response) => 
        {
          loader.hide();
          this.$swal("",response.data.message, "success");
          this.hideModal('modal-follower');
          this.loadData();
          this.loadUsers(this.groupId);
        })
        .catch((error) => 
        {
        loader.hide();
        var html ="<p class='small'>"+ error.response.data.message+"</p>";
        this.$swal("",html,"error");
        });
      },deleteData(id){
        let loader= this.$loading.show();
        axios.delete(`${this.baseUrl}/Group/Delete/`+id, { headers: { Authorization: `Bearer ${this.user.jwt}`},
        }).then((response) => 
        {
          this.loadData();
          loader.hide();
          this.$swal("",response.data.message, "success");
        })
        .catch((error) => 
        {
          loader.hide();
          var html ="<p class='small'>"+ error.response.data.message+"</p>";
          this.$swal("",html,"error");
        });
      },deleteConfirm(id){
          this.$swal({ 
          title: "WARNING",
          text: "Are you sure? Will be deleted along with the connected data.",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Yes",
          cancelButtonText:"No",
          }).then((result) => {
            if (result.value) 
            {
              this.deleteData(id)
            }
          });
      },showModal(modalid) {
        this.$root.$emit('bv::show::modal',modalid)
      },hideModal(modalid) {
        this.$root.$emit('bv::hide::modal',modalid)
      }
    }
  };
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row mb-3" v-if="user.rol=='admin' | user.rol=='teacher'">
        <div class="col-lg-12">
            <b-button v-b-modal.modal-lg variant="primary" class="btn btn-success"><i class="fa fa-users"></i> + ADD NEW GROUP</b-button>
        </div>
    </div>
    <div class="row" v-if="this.groups.length==0">
      <div class="col-12">
       <h2 class="text-danger">You don't have a group yet.</h2> 
       <p>if you wanna create group clik   <a href="#" type="button" v-b-modal.modal-lg>+ ADD NEW GROUP</a> </p>
      </div>
    </div>
    <div class="row">
        <div class="col-md-4" v-for="(item, index) in groups" :key="index">
            <div class="card">
                <router-link :to="{ path: 'groupdetails/'+item.id}" v-b-tooltip.bottom :title="item.name">
                    <div class="card-body">
                    <div class="d-flex">
                        <div class="flex-1 overflow-hidden" style="width:100%">
                            <p class="text-truncate font-size-14 mb-2"><i class="fa fa-users"></i> {{item.name}}</p>
                            <span class="float-right" v-b-tooltip.hover title="Encrypted login." v-if="item.isPassword"><i class="fa fa-lock"></i></span>
                              <span class="float-right mr-1" v-b-tooltip.hover title="Only Teacher Group." v-if="item.isOnlyTeacher"><i class="fa fa-user"></i></span>
                            <p>{{ item.explanation }}</p>
                        </div>
                    </div>
                </div>
                </router-link>
                <div class="card-body border-top py-3">
                    <div class="text-truncate">
                        <a :href="'groupdetails/'+item.id">
                          <span class="btn btn-sm btn-primary mr-1"><i class="fa fa-users" v-b-tooltip.hover title="Members"> </i> {{item.groupUsers.length}} </span>
                          <span class="btn btn-sm btn-info"><i class="fa fa-envelope" v-b-tooltip.hover title="Posts"> </i> {{item.groupPosts.length}} </span>
                        </a>
                        <b-button v-b-tooltip.hover title="Add Members" v-b-modal.modal-follower v-if="item.ownerId==user.id | user.rol=='admin'"  class="btn btn-sm btn-info float-right ml-1" @click="loadUsers(item.id)"><i class="fa fa-user-plus"></i></b-button>
                        <b-button v-b-tooltip.hover title="Delete" class="btn btn-sm btn-danger float-right ml-1" @click="deleteConfirm(item.id)"><i class="fa fa-trash"></i></b-button>
                        <b-button v-b-modal.modal-edit  v-b-tooltip.hover title="Edit"  class="btn btn-sm btn-warning float-right ml-1" @click="initialData(item.id)"><i class="fa fa-edit"></i></b-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <b-modal id="modal-lg" size="lg" title="New Group Items" title-class="font-18" hide-footer>
        <form @submit.prevent="addData()" id="add-group-form">
            <div class="row">
                <div class="col-sm-12">
                    <div class="form-group">
                        <label>Group Name (*)</label>
                        <input type="text" class="form-control" name="name" autocomplete="off"/>
                    </div>
                </div>
               
            </div>
            <div class="row">
                <div class="col-sm-3">
                    <div class="form-group">
                        <label>Teacher Capacity</label>
                        <input type="number" class="form-control" name="groupTeacherCapacity" max="100" maxlength="100" min="0" minlength="0" onkeydown="return false" value="0" autocomplete="off"/>
                    </div>
                </div>
                <div class="col-sm-3">
                    <div class="form-group">
                        <label>Student Capacity</label>
                        <input type="number" class="form-control" name="groupStudentCapacity" max="100" maxlength="100" min="0" minlength="0" onkeydown="return false" value="0" autocomplete="off"/>
                    </div>
                </div>
                <div class="col-sm-3">
                    <div class="form-check" v-b-tooltip.hover  title="If you choose this, only teachers will be able to enter the group.">
                      <input class="form-check-input" type="checkbox" value=""  v-model="isOnlyTeacher"  id="isOnlyTeacher" >
                     <label class="form-check-label" for="isOnlyTeacher"><i class="fa fa-info-circle"></i> Is Only Teacher? </label>
                   </div>
                </div>
                <div class="col-sm-3">
                    <div class="form-group"  v-b-tooltip.hover  title="If you choose this, members will be asked for a password.">
                        <input type="checkbox" class="btn-check" id="isPassword" v-model="isPassword" autocomplete="off" @change="isPasswordFunc()"/>
                        <label for="isPassword"> <i class="fa fa-info-circle"></i>  Is Password? </label>
                        <input type="password" name="password" id="password" class="form-control bg-danger" max="8" maxlength="8"  disabled/>
                    </div>
                </div>
            </div>
           
            <div class="row">
                <div class="col-sm-12">
                    <div class="form-group">
                        <label>Explanation</label>
                        <textarea name="explanation" class="form-control"></textarea>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <button type="submit" class="btn btn-warning"><i class="fa fa-plus"></i> ADD GROUP</button>
                </div>
            </div>
        </form>
    </b-modal>
    <b-modal id="modal-follower" scrollable size="lg" title="New Members" title-class="font-18" hide-footer>
        <form  id="add-follower-form">
            <div class="form-group">
                <label>Group followers (*)</label>
                <input type="text" name="" id="" placeholder="filter by type or member name" v-model="filter" class="form-control">
                <table class="table" id="usertable">
                  <thead>
                    <tr>
                      <th>Type</th>
                      <th>Name</th>
                      <th>Scholl</th>
                      <th>Class</th>
                      <th><input type="checkbox" @change="AllCheck()" id="allcheck" /></th>
                    </tr>
                    
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in filteredrows" :key="index">
                       <td>{{item.userGroup.name}}</td>
                       <td>{{item.fullName}}</td>
                       <td>{{item.school}}</td>
                       <td>{{item.classRoom}}</td>
                       <td><input type="checkbox" name="checkuser" :value="item.id" @change="selectedUsers($event)" :checked="groups.find(x=>x.id==groupId).groupUsers.filter(x=>x.userId==item.id).length>0" /></td>
                    </tr>
                  </tbody>
                </table>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <button type="button" @click="addFollowers()" class="btn btn-warning btn-sm"><i class="fa fa-plus"></i> ADD FOLLOWER</button>
                </div>
            </div>
        </form>
    </b-modal>
    <b-modal id="modal-edit" size="lg" title="Edit Group Item" title-class="font-18" hide-footer>
        <form @submit.prevent="editData()" id="edit-file-form">
          <div class="row">
                <div class="col-sm-12">
                    <div class="form-group">
                        <label>Group Name (*)</label>
                        <input type="text" class="form-control" v-model="name" name="name" autocomplete="off"/>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-3">
                    <div class="form-group">
                        <label>Teacher Capacity</label>
                        <input type="number" class="form-control" v-model="groupTeacherCapacity" name="groupTeacherCapacity" max="100" maxlength="100" min="0" minlength="0" onkeydown="return false" value="0" autocomplete="off"/>
                    </div>
                </div>
                <div class="col-sm-3">
                    <div class="form-group">
                        <label>Student Capacity</label>
                        <input type="number" class="form-control" v-model="groupStudentCapacity" name="groupStudentCapacity" max="100" maxlength="100" min="0" minlength="0" onkeydown="return false" value="0" autocomplete="off"/>
                    </div>
                </div>
                <div class="col-sm-3">
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" v-model="isOnlyTeacher"  value="" id="isOnlyTeacher" >
                     <label class="form-check-label" for="isOnlyTeacher"> Is Only Teacher? </label>
                   </div>
                </div>
                <div class="col-sm-3">
                    <div class="form-group">
                        <input type="checkbox" class="btn-check" v-model="isPassword" id="isPassword" autocomplete="off" @change="isPasswordFunc()"/>
                        <label for="isPassword"> Is Password? </label>
                        <input type="password" v-model="password" name="password" id="password" class="form-control bg-danger" max="8" maxlength="8"  disabled/>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12">
                    <div class="form-group">
                        <label>Explanation</label>
                        <textarea v-model="explanation" name="explanation" class="form-control"></textarea>
                    </div>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-md-12">
                    <button type="submit" class="btn btn-warning"><i class="fa fa-check"></i> SAVE WORK</button>
                </div>
            </div>
        </form>
    </b-modal>
  </Layout>
</template>